import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
   showNotification = false;
   notificationMessage = '';
   typeNotif = '';

  notifySuccess(message: string) {
    this.notificationMessage = message;
    this.typeNotif = 'success';
    this.showNotification = true;
    setTimeout(() => {
      this.closeNotification();
    }, 5000); // Fermer la notification après 5 secondes 
  }

  notifyError(message: string) {
    this.notificationMessage = message;
    this.typeNotif = 'error';
    this.showNotification = true;
    setTimeout(() => {
      this.closeNotification();
    }, 5000); // Fermer la notification après 5 secondes 
  }

  notifyWarning(message: string) {
    this.notificationMessage = message;
    this.typeNotif = 'warning';
    this.showNotification = true;
    setTimeout(() => {
      this.closeNotification();
    }, 5000); // Fermer la notification après 5 secondes 
  }

  private closeNotification() {
    this.showNotification = false;
    this.notificationMessage = '';
  }
}
