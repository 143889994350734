import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-layoutdashboard',
  templateUrl: './layoutdashboard.component.html',
  styleUrls: ['./layoutdashboard.component.css']
})
export class LayoutdashboardComponent implements AfterViewInit{

  viewmodal  = false;
  showloader  = false;

  constructor(
    public notificationService: NotificationService,
    public loaderService: LoaderService,
    private localstorageService :LocalstorageService,
    private cd : ChangeDetectorRef
    ) {
      this.localstorageService.viewmodal$.subscribe((value) => {
        this.viewmodal= value
      });
      if(localStorage.getItem('alreadyco')){
        this.viewmodal= true;
      }
    }

    ngAfterViewInit(): void {
      this.loaderService.showLoaderSubject.subscribe(( value )=> {
        if(value > 0){
          this.showloader =true
        }else{
          this.showloader =false
        }
    });

    this.cd.detectChanges();

    }


}
