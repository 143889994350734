import { Injectable } from '@angular/core';
import { AgenceId } from '../models/agencyId';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class DropdownAgencyService {
    
  dropdownList: AgenceId[] = [];
  selectedAgencies: AgenceId[] = [];
  updateAgencies$: BehaviorSubject<AgenceId[]> = new BehaviorSubject<AgenceId[]>(this.selectedAgencies);
    
  public init(agencies: AgenceId[]): void{
    this.dropdownList = agencies;
    this.selectedAgencies = [];
    if(agencies.length > 0){
      this.selectedAgencies.push(agencies[0]);
    }   
  }

  public updateSelectedAgencies(newAgencies: AgenceId[]){
    this.selectedAgencies = newAgencies;
    this.updateAgencies$.next(newAgencies);
  }
  
}
