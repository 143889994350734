<div class="modal">
  <div *ngIf="!clicksend" class="modal-content">
    <div class="headermodal">
      <div class="titlenclose">
        <h4 class="title">Choisir le format :</h4>
        <div class="erreur" *ngIf="invalid_choice">
          Choisir au moins un format de partage.
        </div>
        <i class="fa-solid fa-xmark clickable" (click)="closeModal()" (keydown.enter)="closeModal()" tabindex="0"></i>
      </div>
    </div>

    <div class="previewmail">
      <div class="preview clickable" (click)="choicegraph()" [ngClass]="{'previewactiv' : previewgraph }"
        (keydown.enter)="choicegraph()" tabindex="0">
        <img class="imgpreview" src="{{graph_screenshot}}" alt="graphique" />
        <div class="titrepreview">
          <span>Graph</span>
        </div>
      </div>

      <div class="preview clickable" (click)="choiceexcel()" [ngClass]="{'previewactiv' : previewexcel }"
        (keydown.enter)="choiceexcel()" tabindex="0" >
        <img class="imgpreview"
          src="../../../assets/image/excel.png"
          alt="excel" />
        <div class="titrepreview">
          <span>Excel</span>
        </div>
      </div>
    </div>
    <div class="btns" *ngIf="!showMailForm">
      <button type="button" class="cancel" (click)="closeModal()">Annuler</button>
      <button type="button" class="download" (click)="downloadScreenshot()" >Télécharger</button>
      <button type="button" class="send" (click)="toggleMailForm()">Envoyer</button>
    </div>

    <div *ngIf="showMailForm" class="form">
      <h3>Envoi par mail</h3>

      <span class="subtitle">Partager une capture d'écran d'un élément précis ou de la page complète par mail simplement
        en remplissant
        le formulaire suivant:</span>
      <div class="required">* <span>Champs Obligatoires</span>
      </div>


      <form [formGroup]="this.sendForm" (ngSubmit)="onSubmit()">
        <div class="erreur" *ngIf="required">
          L'adresse email est requise.
        </div>
        <div class="erreur" *ngIf="invalid_mail">
          L'adresse email n'est pas valide.
        </div>

        <div class="inputdest">
          <h4 class="titledest">A<span class="erreur">*</span> :</h4>
          <input type="email" id="recipient" formControlName="email" placeholder="exemple@ciorem.fr">
        </div>

        <div class="inputobject">
          <h4 class="titleobject">Objet :</h4>
          <input type="text" id="subject" name="subject" formControlName="subject" placeholder="{{object_default}}">
        </div>

        <h4 class="title">Commentaire :</h4>
        <textarea type="text" id="comments" rows="4" name="comments" formControlName="comments"
          placeholder="Entrez un commentaire"></textarea>

        <div class="btns">
          <button type="button" class="cancel" (click)="closeModal()">Annuler</button>
          <button type="submit" class="send">Envoyer</button>
        </div>
      </form>
    </div>


  </div>
</div>