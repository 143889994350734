import { Injectable } from '@angular/core';
import { MapData } from '../models/mapData';
import { StatusGraph } from '../models/status-graph';
import { StateGraph } from '../models/state-graph';
import { DelayGraph } from '../models/delay-graph';


@Injectable({
  providedIn: 'root'
})
// FR: Service qui récupere les datas du http service pour l'envoyer dans le mailer 
// EN: Service that retrieves data from the http service to send it to the mailer
export class CsvDataService {

  delivery_report_by_status_global: StatusGraph[] = [];
  state_delivery_reports_global: StateGraph[] = [];
  delay_global : DelayGraph[] = [];
  private deliveryAndExpeditionByDepartment: { [department: string]: MapData[] } = {};

  
  getDeliveryReportByStatusGlobal(): StatusGraph[] {
    return this.delivery_report_by_status_global; 
  }
  
  setDeliveryReportByStatusGlobal(value: StatusGraph[]) {
    this.delivery_report_by_status_global = value;
  }

  getStateOfDeliveryReportsGlobal(): StateGraph[] {
    return this.state_delivery_reports_global;
  }
  
  setStateOfDeliveryReportsGlobal(value: StateGraph[]) {
    this.state_delivery_reports_global = value;
  }
   
  getDelayGlobal(): DelayGraph[] {
    return this.delay_global;
  }
  
  setDelayGlobal(value: DelayGraph[]) {
    this.delay_global = value;
  }

  setDeliveryAndExpeditionByDepartment(department: string, data: MapData[]): void {
    this.deliveryAndExpeditionByDepartment[department] = data;
  }

  getDeliveryAndExpeditionByDepartment(department: string): MapData[] {
    return this.deliveryAndExpeditionByDepartment[department] || [];
  }
}
