import { Component } from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-layoutlogin',
  templateUrl: './layoutlogin.component.html',
  styleUrls: ['./layoutlogin.component.css']
})
export class LayoutloginComponent {

  constructor(
    public notificationService: NotificationService,
    ) {
    }

}
