import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


/**
 * The loader service is to be used for API requests, preventing users from spam clicks and other issues by blocking them.
 * Le loader service est a utiliser pour les requetes api, bloquer l'utilisateur pour eviter les spam click et autre probleme
 */
export class LoaderService {
  // liste les requetes en attentes ,Si > 0 alors le loader s'affiche
  // List the pending requests. If the count is greater than 0, the loader is displayed.
   showLoader = 0;
   public showLoaderSubject = new BehaviorSubject<number>(0);


  /**
   * Method to be called at the click event to trigger an API request as soon as possible.
   * Methode a appeler au plutot du click qui active une requete
   */
  loading(){
    this.showLoader = this.showLoader + 1;
    this.showLoaderSubject.next(this.showLoader)
  }

  /**
   * Method to be called at the end of the request rendering.
   * Methode a appeler a la fin de la restiution de la requete
   */
  endLoading(){
    this.showLoader = this.showLoader - 1;
    this.showLoaderSubject.next(this.showLoader)
  }

 
}
