import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutdashboardComponent } from "./layout/layout-dashboard/layoutdashboard.component";
import { LayoutloginComponent } from './layout/layout-login/layoutlogin.component';



const routes: Routes = [
  {
    path: "dashboard",
    component: LayoutdashboardComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)

      }
    ]
  },
  {
    path: "login",
    component: LayoutloginComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)

      }
    ]
  },
  {
    path: "",
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: "**",
    redirectTo: '/login',
    pathMatch: 'full'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
