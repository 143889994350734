
<div class="content">
    
    <app-notification *ngIf="notificationService.showNotification" [message]="notificationService.notificationMessage" [type]="notificationService.typeNotif" ></app-notification>
    
    <router-outlet></router-outlet>

</div>




