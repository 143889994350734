import { Component } from '@angular/core';
import { LocalstorageService } from '../../core/services/localstorage.service'

@Component({
  selector: 'app-popupnewco',
  templateUrl: './popupnewco.component.html',
  styleUrls: ['./popupnewco.component.css']
})
export class PopupnewcoComponent {

  constructor(
    private localstorageService : LocalstorageService
  ){

  }

  save(){
    this.localstorageService.savefirstmodal()
  }

}
