
<div class="content">
    
    <app-notification *ngIf="notificationService.showNotification" [message]="notificationService.notificationMessage" [type]="notificationService.typeNotif" ></app-notification>

    <app-popupnewco *ngIf="!viewmodal"></app-popupnewco>

    <app-loader *ngIf="showloader"></app-loader>
    
    <app-navbar></app-navbar>
    
    <router-outlet></router-outlet>

    <app-footer></app-footer>

    

</div>




